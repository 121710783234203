import * as React from 'react';
import { useNavigate } from "react-router-dom";

import client from './api';
import keychain from './keychain';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CircularProgress, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FeedIcon from '@mui/icons-material/Feed';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

import HeaderBar from './HeaderBar';
import FeedCard from './FeedCard';

class Feeds extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            filterOpen: false,
            filterAnchor: null,
            alertData: undefined,
            moreToLoad: false,
            shouldDisplaySnackbar: false,
            sponsorData: undefined
        };

        this.didRequestAlerts = false
        this.didRequestSponsorship = false
    }

    componentDidMount() {
        if (this.didRequestAlerts === false) {
            this.didRequestAlerts = true;
            this.requestAlerts();
        }

        if (this.didRequestSponsorship === false) {
            this.didRequestSponsorship = true;

            if (keychain.isLoggedIn()) {
                this.requestSponsorship();
            }
            else if (keychain.doesKeyExist('location_zipcode')) {
                this.requestSponsorship();
            }
            else {
                this.requestIPAddress();
            }
        }
    }

    toggleMenu = (event) => {
        this.toggleDrawer(!this.state.menuOpen)
    };

    toggleDrawer = (open) => {
        if (this.state.menuOpen === open) {
            return;
        }

        this.setState({menuOpen: open});
    };

    openFilter = (event) => {
        this.setState({filterOpen: true, filterAnchor: event.currentTarget})
    };

    requestAlerts() {
        client.get('alerts/feeds/?order=desc&resolved=false')
        .then(res => {
            this.updateAlertData(res.data, res.data.length === 50);
        });
    };

    requestAdditionalAlerts() {
        let lastAlert = this.state.alertData[this.state.alertData.length - 1];
        let lastDate = Date.parse(lastAlert.created_at) - 100000000;
        client.get(`alerts/feeds/?order=desc&resolved=false&start=${lastDate}`)
        .then(res => {
            let combinedList = [...new Set([...this.state.alertData, ...res.data])];
            this.updateAlertData(combinedList, res.data.length === 50);
        });
    };

    updateAlertData = (alerts, moreToLoad) => {
        var finalAlerts = [];
        for (let index in alerts) {
            let alert = alerts[index];
            let now = new Date();
            let createdDate = new Date(alert.created_at);
            let maxDateRange = 180.0 * 24.0 * 60.0 * 60.0 * 1000.0;
            if ((now - createdDate) < maxDateRange) {
                finalAlerts.push(alert);
            }
        }
        this.setState({alertData: finalAlerts, moreToLoad:moreToLoad});
    };

    requestSponsorship() {
        var url = 'sponsorships/'
        if (keychain.doesKeyExist('location_zipcode')) {
            url = url.concat(keychain.getWithExpiry('location_zipcode'))
        }
        client.get(url)
        .then(res => {
            this.setState({sponsorData: res.data});
        })
        .catch((e) => {
            console.log('Error in get sponsorship', e);
            this.setState({sponsorData: {}});
        });
    };

    requestLocationDetails = (ip) => {
        const url = `/sponsorships/lookup/?ip=${ip}`
        client.get(url)
        .then(res => {
            let zipcode = `?zipcode=${res.data.zipcode}`;
            let ttl = 14*24*60*60*1000;
            keychain.setWithExpiry('location_zipcode', zipcode, ttl);
            this.requestSponsorship();
        })
        .catch((e) => {
            console.log('Error in get ip location', e)
        });
    };

    requestIPAddress() {
        const url = 'https://api.ipify.org?format=json';
        fetch(url)
        .then(res => res.json())
        .then((resJson) => {
            this.requestLocationDetails(resJson.ip);
        })
        .catch((e) => {
            console.log('Error in get ip address', e)
        });
    }

    shareAlert = (alertID) => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_DOMAIN_NAME}/feeds/${alertID}/`);
        this.setState({shouldDisplaySnackbar: true});
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({shouldDisplaySnackbar: false});
    };

    render() {
        let menuData;
        if (keychain.isLoggedIn()) {
            menuData = [
                {name: 'Account', icon: <AccountCircleIcon/>, url:'/account'},
                {name: 'Sponsor', icon: <LoyaltyIcon/>, url:'/sponsor'},
                {name: 'Feeds', icon: <FeedIcon/>, url: '/feeds'}
            ];
        }
        else {
            menuData = [
                {name: 'Login', icon: <LockOpenIcon/>, url: '/login'},
                {name: 'Sign up', icon: <PersonAddAltIcon/>, url: '/signup'},
                {name: 'Sponsor', icon: <LoyaltyIcon/>, url:'/sponsor'},
                {name: 'Feeds', icon: <FeedIcon/>, url:'/feeds'}
            ];
        }

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClose={() => this.toggleDrawer(false)}>
            <List>
                {menuData.map((item, index) => (
                    <ListItem button component="a" href={item.url} disablePadding key={index}>
                        <ListItemButton>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Box>
    );

    let loadMoreButton;
    if (this.state.moreToLoad) {
        loadMoreButton = <Button
                type="submit"
                variant="contained"
                onClick={() => { this.requestAdditionalAlerts(); }}
                sx={{ mt: 3, mb: 2, textTransform: "none" }}>
                Load more
            </Button>
    }

    var alertFeedContent = <Box />;
    if (this.state.alertData === undefined) {
        alertFeedContent = <Box
            sx={{
                pt: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <CircularProgress color="primary" />
                    </Box>;
    }
    else {
        let top_padding = this.state.sponsorData === undefined ? 10 : 5;
        alertFeedContent = <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{pt:top_padding}}>
            {this.state.alertData.map((alert, index) => (
                <Grid item xs="auto" key={index}>
                    <FeedCard 
        alert={alert}
        selectedAlert={(alertID) => this.props.navigate(`/feeds/${alertID}/`)}
        shareAlert={this.shareAlert}/>
                </Grid>
            ))}
            </Grid>
    }

    var sponsorContent = <Box />;
    if (this.state.sponsorData) {
        if (this.state.sponsorData.zipcode === undefined) {
            sponsorContent = <Box
                sx={{
                    pt: 9,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'action.selected'
                }}
                >
                    <Typography component="h1" variant="h6" align='center' color="text.secondary" sx={{ mt: 2, mx: 4 }}>
                        Help keep pets in your neighborhood safe by becoming a Spot Sponsor!
                    </Typography>
                    <Link href="/sponsor" variant="h6" sx={{mt:1, mb: 3, mx:4}}>
                        Join today
                    </Link>
            </Box>;
        }
        else {
            var company_url = this.state.sponsorData.company_url;
            if (!company_url.includes('http')) {
                company_url = 'https://' + company_url;
            }

            var descriptionTypography = undefined;
            if (this.state.sponsorData.description !== undefined) {
                descriptionTypography = <Typography component="h1" variant="h6" align='center' color="text.secondary" sx={{ mb: 3, mx: 4 }}>
                    {this.state.sponsorData.description}
                </Typography>
            }

            sponsorContent = <Box
                sx={{
                    pt: 9,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'action.selected'
                }}
                >
                    <Typography component="h1" variant="h6" align='center' color="text.secondary" sx={{ mt: 2, mx: 4 }}>
                        Pet safety in your neighborhood is sponsored by:
                    </Typography>
                    <Link href={company_url} target="_blank" variant="h4" sx={{my:2, mx:4, fontWeight: 600}}>
                        {this.state.sponsorData.company_name}
                    </Link>
                    {descriptionTypography}
            </Box>;
        }
    }

    return (
        <React.Fragment>
            <Box sx={{backgroundColor:"background.default"}}>
                <Box sx={{ flexGrow: 1 }}>
                    <HeaderBar menuAction={this.toggleMenu}/>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.menuOpen}
                    onClose={() => this.toggleDrawer(false)}
                >
                    {list()}
                </Drawer>
                <Box
                    sx={{minHeight: "100vh",
                        backgroundSize: 'cover'}}>
                    {sponsorContent}
                    {alertFeedContent}
                    {loadMoreButton}
                </Box>
            </Box>
            <Snackbar open={this.state.shouldDisplaySnackbar} autoHideDuration={3000} onClose={this.handleCloseSnackbar}>
                <Alert onClose={this.handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                Link copied to clipboard!
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
  }
}

function FeedsWithNavigate(props) {
    let navigate = useNavigate();
    return <Feeds {...props} navigate={navigate} />
}

export default FeedsWithNavigate;
