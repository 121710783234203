import { createTheme } from '@mui/material/styles';
 
const theme = (mode) => createTheme({
    palette: {
        mode: mode,
        primary: {
            main: '#fb4d52',
        },
        secondary: {
            main: '#f50057',
        },
        toolbarButton: {
            main: '#ffffff',
            contrastText: '#000000'
        },
        toolbarLink: {
            main: '#ffffff'
        },
        landingCopy: {
            main: '#ffffff'
        },
        primaryBackground: {
            main: '#232323'
        },
        spotText: {
            main: '#ffffff',
            secondary: '#868686'
        },
        input: {
            main: '#FFFFFF',
            secondary: '#868686'
        },
        dividerBackground: {
            main: '#868686'
        },
        spotWarning: {
            main: '#FFCC00'
        },
        spotResolved: {
            main: '#3A9C51'
        },
    },
    typography: {
        fontFamily: [
            'Avenir-Light',
          ].join(',')
      }
  })

  export default theme;