import * as React from 'react';

import keychain from './keychain';
import MediaQuery from 'react-responsive'

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import MenuIcon from '@mui/icons-material/Menu';

class HeaderBar extends React.Component {
    render() {
        // Handle login state
        let button;
        let signUpButton;
        if (keychain.isLoggedIn()) {
            button = <Button
                  variant="contained"
                  color="toolbarButton"
                  href="/account"
                  sx={{ my: 0, ml: 5, textTransform: "none" }}>
                    Account
                </Button>;
        }
        else {
            button = <Button
                  variant="contained"
                  color="toolbarButton"
                  href="/login"
                  sx={{ my: 0, ml: 5, textTransform: "none" }}>
                    Login
                </Button>;

            signUpButton = <Link
                    variant="button"
                    color="toolbarButton.main"
                    textTransform="none"
                    href="/signup"
                    sx={{ my: 1, mx: 1.5 }}>
                    Sign up
                </Link>;
        }

        return (
            <AppBar>
              <Toolbar sx={{height: 64, bgcolor: 'primary.main'}}>
                <img src="/nav_logo.png" alt="Spot logo"></img>
                <Typography variant="h5" component="div" sx={{ flexGrow: 1, ml: 2 }}>
                  Spot
                </Typography>
                <MediaQuery maxWidth={1224}>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        onClick={() => { this.props.menuAction(); }}>
                        <MenuIcon />
                    </IconButton>
                </MediaQuery>

                <MediaQuery minWidth={1224}>
                    <Link
                    variant="button"
                    color="toolbarButton.main"
                    textTransform="none"
                    href="/feeds"
                    sx={{ my: 1, mx: 1.5 }}>
                        Feeds
                    </Link>
                    <Link
                    variant="button"
                    color="toolbarButton.main"
                    textTransform="none"
                    href="/sponsor"
                    sx={{ my: 1, mx: 1.5 }}>
                        Sponsor
                    </Link>
                    {signUpButton}
                    {button}
                </MediaQuery>
              </Toolbar>
            </AppBar>
        );
    }
}

export default HeaderBar;
