import axios from 'axios';
import keychain from './keychain';

const getClient = (baseUrl = process.env.REACT_APP_API_BASE_URL) => {

  const options = {
    baseURL: baseUrl
  };

  if (localStorage.getItem('accessToken')) {
    options.headers = {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    };
  }

  const client = axios.create(options);

  // Add a request interceptor
  client.interceptors.request.use(
    requestConfig => requestConfig,
    (requestError) => {
      return Promise.reject(requestError);
    },
  );

  // Add a response interceptor
  client.interceptors.response.use(
    response => response,
    (error) => {
      const originalRequest = error.config;
      if ((error.response.status === 401 || error.response.status === 422) && originalRequest.url === 'auth/refresh/') {
          logout();
          return Promise.reject(error);
      }
      
      if (error.response.status === 401 && !originalRequest._retry && keychain.isLoggedIn()) {
        originalRequest._retry = true;
        return handleRefresh()
        .then(res => {
          localStorage.setItem('accessToken', res.data.access_token);
          localStorage.setItem('refreshToken', res.data.refresh_token);
          client.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
          originalRequest.headers = {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          };
          return client(originalRequest);
        })
        .catch(error => {
          logout();
          return Promise.reject(error);
        });
    }
      return Promise.reject(error);
    },
  );

  const refreshClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
    }
  });
  
  function handleRefresh() {
    return new Promise((resolve, reject) => {
      refreshClient
        .post('auth/refresh/')
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          resolve(error);
        });
    });
  };

  function logout() {
    keychain.logout();
    window.location.replace('/login');
  }

  return client;
};

class ApiClient {
  constructor(baseUrl = null) {
    this.client = getClient(baseUrl);
  }

  get(url, conf = {}) {
    return this.client.get(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  delete(url, conf = {}) {
    return this.client.delete(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  post(url, data = {}, conf = {}) {
    return this.client.post(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  put(url, data = {}, conf = {}) {
    return this.client.put(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}

export { ApiClient };

/**
 * Base HTTP Client
 */
const api = {
  // Provide request methods with the default base_url
  get(url, conf = {}) {
    return getClient().get(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  delete(url, conf = {}) {
    return getClient().delete(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  post(url, data = {}, conf = {}) {
    return getClient().post(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  put(url, data = {}, conf = {}) {
    return getClient().put(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};

export default api;
