import * as React from 'react';
import { useNavigate } from "react-router-dom";

import client from './api';
import keychain from './keychain';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FeedIcon from '@mui/icons-material/Feed';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

import HeaderBar from './HeaderBar';

import ManageSponsorshipTable from './ManageSponsorshipTable'

class ManageSponsorhip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            sponsorshipData: undefined
        };

        this.didRequestSponsorshipDetails = false
    }

    componentDidMount() {
        if (this.didRequestSponsorshipDetails === false) {
            this.didRequestSponsorshipDetails = true;
            this.requestSponsorshipDetails();
        }
    }

    toggleMenu = (event) => {
        this.toggleDrawer(!this.state.menuOpen)
    };

    toggleDrawer = (open) => {
        if (this.state.menuOpen === open) {
            return;
        }

        this.setState({menuOpen: open});
    };

    requestSponsorshipDetails = () => {
        client.get(`users/${localStorage.getItem('user_id')}/sponsorships/`)
        .then(res => {
            this.setState({sponsorshipData: res.data});
        })
        .catch(error => {
            
        });
    };

    selectedSponsorship = (uuid) => {
        this.props.navigate(`/sponsor/manage/${uuid}/`)
    };

    selectedSponsorNow = () => {
        this.props.navigate('/sponsor');
    };

    render() {
        let menuData;
        if (keychain.isLoggedIn()) {
            menuData = [
                {name: 'Account', icon: <AccountCircleIcon/>, url:'/account'},
                {name: 'Sponsor', icon: <LoyaltyIcon/>, url:'/sponsor'},
                {name: 'Feeds', icon: <FeedIcon/>, url: '/feeds'}
            ];
        }
        else {
            window.location.replace('/login');
        }

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClose={() => this.toggleDrawer(false)}>
            <List>
                {menuData.map((item, index) => (
                    <ListItem button component="a" href={item.url} disablePadding key={index}>
                        <ListItemButton>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Box>
    );

    var sponsorshipTable = <Box />;
    if (this.state.sponsorshipData === undefined) {
        sponsorshipTable = <Box
            sx={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <CircularProgress color="primary" />
                    </Box>;
    }
    else if (this.state.sponsorshipData.length > 0) {
        sponsorshipTable = <ManageSponsorshipTable
            sponsorshipData={this.state.sponsorshipData}
            selectedSponsorship={this.selectedSponsorship}/>;
    }
    else {
        sponsorshipTable = <Box
            sx={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <Typography component="h1" variant="h6" align='center' color="text.secondary" sx={{ mt: 2, mx: 4 }}>
                    It looks like you're not currently sponsoring Spot in your neighborhood.
                </Typography><Typography component="h1" variant="h6" align='center' color="text.secondary" sx={{ mt: 2, mx: 4 }}>
                    Sponsor Spot today to enable free alerts for missing pets in your area, and promote your organization.
                </Typography>

                <Button
                    type="submit"
                    variant="contained"
                    onClick={() => { this.selectedSponsorNow(); }}
                    sx={{ mt: 5, textTransform: "none" }}
                    >
                        Add Sponsorship now
                    </Button>
            </Box>;
    }

    return (
        <React.Fragment>
            <Box sx={{backgroundColor:"background.default"}}>
                <Box sx={{ flexGrow: 1 }}>
                    <HeaderBar menuAction={this.toggleMenu}/>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.menuOpen}
                    onClose={() => this.toggleDrawer(false)}
                >
                    {list()}
                </Drawer>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{minHeight: "100vh",
                        backgroundSize: 'cover'}}>
                    <Box
                        sx={{width: "100%",
                            maxWidth: "600px",
                            minHeight: "100vh",
                            backgroundSize: 'cover',
                            border: 1,
                            borderColor: "spotText.secondary",
                            p: 3}}>
                        {sponsorshipTable}
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
  }
}

function ManageSponsorshipWithNavigate(props) {
    let navigate = useNavigate();
    return <ManageSponsorhip {...props} navigate={navigate} />
}

export default ManageSponsorshipWithNavigate;
