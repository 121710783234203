import * as React from 'react';
import PinInput from 'react-pin-input';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import './PinEntry.css';

class PinEntry extends React.Component {
    render() {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                <Typography component="h1" variant="h5" align="center">
                    Please enter the code we just sent to {this.props.phone}
                </Typography>
                <Box sx={{py: 5}}>
                    <PinInput 
                        length={6} 
                        initialValue=""
                        type="numeric" 
                        inputMode="number"
                        style={{padding: '5px'}}  
                        inputStyle={{
                            borderColor: '#cecece',
                            fontSize: '1.5rem',
                            textAlign: 'center',
                            fontFamily: 'Avenir-Heavy',
                            color: '#cecece',
                            borderRadius: '0.4rem',
                            width: '2.5rem',
                            margin: '0.2rem'}}
                        inputFocusStyle={{borderColor: '#fb4d52'}}
                        onComplete={this.props.handlePinCodeEntryComplete}
                        autoSelect={true}
                        autoComplete={true}
                        />
                </Box>
                <Typography component="p" variant="p" color="spotText.secondary"
                    sx={{ mb: 2, fontWeight: 600 }}
                    hidden={this.props.error === false}>
                    Invalid pin code entered. Please try again.
                </Typography>
                <Button
                    onClick={this.props.handleIncorrectPhone}
                    variant="text"
                    sx={{textTransform: "none" }}>
                    Incorrect phone number
                </Button>
                <Button
                    onClick={() => { this.props.handleResendCode(); }}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, textTransform: "none" }}>
                        Resend code
                </Button>
                </Box>
                <Typography variant="body2" align="center" sx={{p: 2}}>
                        {'Copyright © '}
                        <Link href="https://kleincodes.com/" target="_blank">
                            KleinCodes, LLC
                        </Link>{' '}
                        {new Date().getFullYear()}
                    </Typography>
            </Container>
        );
    }
}

export default PinEntry;
