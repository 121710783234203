import * as React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CallRoundedIcon from '@mui/icons-material/CallRounded';
import IosShareIcon from '@mui/icons-material/IosShare';

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import Moment from 'moment';

class FeedAlertView extends React.Component {
    constructor(props) {
        super(props);

        TimeAgo.addLocale(en);
    }

    render() {
        const timeAgo = new TimeAgo('en-US');

        var location = '—'
        let address_municipality = this.props.alert.address_municipality;
        let address_region = this.props.alert.address_region;
        let country = this.props.alert.address_country;
        if (address_municipality !== null && address_region !== null && country !== null) {
            location = `${address_municipality}, ${address_region}, ${country}`
        }

        var date = new Date(this.props.alert.created_at);
        let offset = date.getTimezoneOffset() / 60;
        let hours = date.getHours();
        date.setHours(hours - offset);

        let ownerName = `${this.props.alert.user.first_name} ${this.props.alert.user.last_name}`;

        var resolvedBox = undefined;
        var contactBox = undefined;
        if (this.props.alert.resolved_at !== null) {
            resolvedBox = <Box sx={{backgroundColor:"spotResolved.main", borderRadius: 2, mt: 1.5}}>
                    <Typography gutterBottom variant="h6" component="div" color="text.primary" sx={{ mb: 2, fontWeight: 600, mx: 2, py: 1.5, textAlign:"center" }}>
                        Home safe on {Moment(this.props.alert.resolved_at).format('MMMM D, YYYY')}
                    </Typography>
                </Box>
        } else {
            contactBox = <Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2, fontWeight: 600, mx: 2 }}>
                    If found, please contact: {ownerName}
                </Typography>
                <Divider color="dividerBackground.main" sx={{ m: 2 }} />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    p: 1,
                    m: 1,
                    borderRadius: 1,
                }}>
                    <IconButton
                        aria-label="call"
                        onClick={() => { window.open('tel:6466718749'); } }
                        sx={{ bgcolor: 'primary.main', color: '#FFF' }}
                    >
                        <CallRoundedIcon />
                    </IconButton>
                    <IconButton
                        aria-label="share"
                        onClick={() => { this.props.shareAlert(); } }
                        sx={{ bgcolor: 'primary.main', color: '#FFF' }}
                    >
                        <IosShareIcon />
                    </IconButton>
                </Box>
            </Box>
        }


        return (
            <><Box
                component="img"
                sx={{
                    width: "100%",
                    mt: "64px"
                }}
                alt="Alert image of missing pet"
                src={this.props.alert.thumbnail_url} />
                {resolvedBox}
                <Typography gutterBottom variant="h3" component="div" color="text.primary" sx={{ mx: 2, mt: 2 }}>
                    {this.props.alert.pet_name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1, fontWeight: 600, mx: 2 }}>
                    {timeAgo.format(date, 'mini')} — {location}
                </Typography>
                <Typography variant="h5" color="text.primary" sx={{ m: 2 }}>
                    {this.props.alert.description}
                </Typography>
                {contactBox}
                <Divider color="dividerBackground.main" sx={{ m: 2 }} /></>
        );
    }
}

export default FeedAlertView;
