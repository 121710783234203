import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Helmet } from 'react-helmet';

import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/styles';

import AccountWithNavigate from './Account'
import EditSponsorshipWithNavigate from './EditSponsorship';
import FeedsWithNavigate from './Feeds'
import FeedAlertWithNavigate from './FeedAlert';
import Landing from './Landing';
import Login from './Login'
import ManageSponsorshipWithNavigate from './ManageSponsorship';
import Signup from './Signup'
import Sponsor from './Sponsor';

import Moment from 'moment';

export default function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    return (
        <ThemeProvider theme={theme(prefersDarkMode ? 'dark' : 'light')}>
            <Helmet>
                <title>Spot - Get Pets Home Safely</title>
                <meta name="og:title" content="Spot - Get Pets Home Safely" />
                <meta name="description" content="Helping reunite families with their pets." />
                <meta name="og:description" content="Helping reunite families with their pets." />
                <meta name="og:image" content="https://pbs.twimg.com/media/E-oUCiWVEAAPY8c?format=jpg&name=small" />
            </Helmet>
            <Router>
            <Routes>
                <Route exact path='/account' element={<AccountWithNavigate/>}/>
                <Route exact path='/feeds' element={<FeedsWithNavigate/>}/>
                <Route exact path="/" element={<Landing/>}/>
                <Route exact path="/login" element={<Login/>}/>
                <Route exact path="/signup" element={<Signup/>}/>
                <Route exact path='/sponsor' element={<Sponsor/>}/>
                <Route path='/feeds/:id' element={<FeedAlertWithNavigate/>}/>
                <Route path='/sponsor/manage' element={<ManageSponsorshipWithNavigate/>}/>
                <Route path='/sponsor/manage/:uuid' element={<EditSponsorshipWithNavigate/>}/>
                <Route path="/health" element={<h3>{`${Moment().format()} - ${process.env.REACT_APP_VERSION} - ${process.env.REACT_APP_ENVIRONMENT}`}</h3>}/>
            </Routes>
        </Router>
        </ThemeProvider>
    );
}
