import * as React from 'react';
import { useParams } from 'react-router-dom';

import client from './api';
import keychain from './keychain';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FeedIcon from '@mui/icons-material/Feed';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

import EditSponsorshipForm from './EditSponsorshipForm';
import HeaderBar from './HeaderBar';

class EditSponsorhip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            sponsorshipData: undefined,
            formErrors: [],
            shouldDisplaySuccessSnackbar: false,
            shouldDisplayFailureSnackbar: false
        };

        this.didRequestSponsorshipDetails = false
    }

    componentDidMount() {
        if (this.didRequestSponsorshipDetails === false) {
            this.didRequestSponsorshipDetails = true;
            this.requestSponsorshipDetails();
        }
    }

    toggleMenu = (event) => {
        this.toggleDrawer(!this.state.menuOpen)
    };

    toggleDrawer = (open) => {
        if (this.state.menuOpen === open) {
            return;
        }

        this.setState({menuOpen: open});
    };

    requestSponsorshipDetails = () => {
        client.get(`sponsorships/${this.props.uuid}/`)
        .then(res => {
            this.setState({sponsorshipData: res.data});
        });
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({shouldDisplaySuccessSnackbar: false, shouldDisplayFailureSnackbar: false});
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let name = data.get('companyName');
        let url = data.get('companyURL');
        let zip = data.get('zipcode');
        let description = data.get('description');

        const params = {
            company_name: name,
            company_url: url,
            zipcode: zip,
            description: description
        };

        client.put(`sponsorships/${this.props.uuid}/`, params)
        .then(res => {
            this.setState({shouldDisplaySuccessSnackbar: true, shouldDisplayFailureSnackbar: false, formErrors: []});
        })
        .catch(error => {
            if (error.response.status === 400) {
                let errorData = error.response.data;
                let errorKeys = Object.keys(errorData);
                this.setState({shouldDisplaySuccessSnackbar: false, shouldDisplayFailureSnackbar: true, formErrors: errorKeys});
            } else {
                this.setState({shouldDisplaySuccessSnackbar: false, shouldDisplayFailureSnackbar: true});
            }
        });
    };

    manageSubscription = () => {
        window.open(this.state.sponsorshipData.portal_url, '_blank').focus();
    };

    render() {
        let menuData;
        if (keychain.isLoggedIn()) {
            menuData = [
                {name: 'Account', icon: <AccountCircleIcon/>, url:'/account'},
                {name: 'Sponsor', icon: <LoyaltyIcon/>, url:'/sponsor'},
                {name: 'Feeds', icon: <FeedIcon/>, url: '/feeds'}
            ];
        }
        else {
            menuData = [
                {name: 'Login', icon: <LockOpenIcon/>, url: '/login'},
                {name: 'Sign up', icon: <PersonAddAltIcon/>, url: '/signup'},
                {name: 'Sponsor', icon: <LoyaltyIcon/>, url:'/sponsor'},
                {name: 'Feeds', icon: <FeedIcon/>, url:'/feeds'}
            ];
        }

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClose={() => this.toggleDrawer(false)}>
            <List>
                {menuData.map((item, index) => (
                    <ListItem button component="a" href={item.url} disablePadding key={index}>
                        <ListItemButton>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Box>
    );

    var sponsorshipForm = <Box />;
    if (this.state.sponsorshipData === undefined) {
        sponsorshipForm = <Box
            sx={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <CircularProgress color="primary" />
                    </Box>;
    }
    else {
        sponsorshipForm = <EditSponsorshipForm
            sponsorshipData={this.state.sponsorshipData}
            handleSubmit={this.handleSubmit}
            manageSubscription={this.manageSubscription}
            errors={this.state.formErrors}/>;
    }

    return (
        <React.Fragment>
            <Box sx={{backgroundColor:"background.default"}}>
                <Box sx={{ flexGrow: 1 }}>
                    <HeaderBar menuAction={this.toggleMenu}/>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.menuOpen}
                    onClose={() => this.toggleDrawer(false)}
                >
                    {list()}
                </Drawer>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{minHeight: "100vh",
                        backgroundSize: 'cover'}}>
                    <Box
                        sx={{width: "100%",
                            maxWidth: "600px",
                            minHeight: "100vh",
                            backgroundSize: 'cover',
                            border: 1,
                            borderColor: "spotText.secondary",
                            p: 3}}>
                        {sponsorshipForm}
                    </Box>
                    <Snackbar open={this.state.shouldDisplaySuccessSnackbar} autoHideDuration={3000} onClose={this.handleCloseSnackbar}>
                        <Alert onClose={this.handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        Sponsorship details successfully saved!
                        </Alert>
                    </Snackbar>
                    <Snackbar open={this.state.shouldDisplayFailureSnackbar} autoHideDuration={3000} onClose={this.handleCloseSnackbar}>
                        <Alert onClose={this.handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                        Failed to update sponsorship. Please try again later.
                        </Alert>
                    </Snackbar>
                </Box>
            </Box>
        </React.Fragment>
    );
  }
}

function EditSponsorshipWithNavigate(props) {
    const { uuid } = useParams();
    return <EditSponsorhip {...props} uuid={uuid} />
}

export default EditSponsorshipWithNavigate;
