import * as React from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Typography from '@mui/material/Typography';

class LoginForm extends React.Component {
    render() {
        const index = Math.floor(Math.random() * 16);
        const panelImageURL = '/img/login/' + index.toString() + '.jpeg';

        return (
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${panelImageURL})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOpenIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                    Login
                    </Typography>
                    <Box component="form" noValidate onSubmit={this.props.handleSubmit} sx={{ mt: 1 }}>
                    <MuiPhoneNumber
                        defaultCountry={'us'}
                        disableAreaCodes={true}
                        countryCodeEditable={false}
                        margin="normal"
                        required
                        fullWidth
                        id="phone"
                        label="Phone Number"
                        name="phone"
                        autoComplete="phonenumber"
                        autoFocus
                        variant="outlined"
                        onChange={this.props.handleOnChange}
                        sx={{
                            svg: {
                              height: "20px",
                            },
                          }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: "none" }}
                    >
                        Login
                    </Button>
                    <Grid container>
                        <Grid item>
                        <Link href="/signup" variant="body2">
                            {"Don't have an account? Sign Up"}
                        </Link>
                        </Grid>
                    </Grid>
                    <Typography variant="body2" align="center" sx={{p: 4}}>
                        {'Copyright © '}
                        <Link href="https://kleincodes.com/" target="_blank">
                            KleinCodes, LLC
                        </Link>{' '}
                        {new Date().getFullYear()}
                    </Typography>
                    </Box>
                </Box>
                </Grid>
            </Grid>
        );
    }
}

export default LoginForm;
