import * as React from 'react';

import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import client from './api';
import keychain from './keychain';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FeedIcon from '@mui/icons-material/Feed';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

import FeedAlertView from './FeedAlertView';
import HeaderBar from './HeaderBar';

class FeedAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            alert: undefined,
            shouldDisplaySnackbar: false
        };

        this.didRequestAlert = false
    }

    componentDidMount() {
        if (this.didRequestAlert === false) {
            this.didRequestAlert = true;
            this.requestAlert();
        }
    }

    toggleMenu = (event) => {
        this.toggleDrawer(!this.state.menuOpen)
    };

    toggleDrawer = (open) => {
        if (this.state.menuOpen === open) {
            return;
        }

        this.setState({menuOpen: open});
    };

    requestAlert() {
        client.get(`alerts/${this.props.alertID}/`)
        .then(res => {
            this.setState({alert: res.data});
        });
    };

    shareAlert = () => {
        if (navigator.share) {
            navigator.share({
              title: 'Have you seen this pet?',
              url: `${process.env.REACT_APP_DOMAIN_NAME}/feeds/${this.state.alert.id}/`
            })
          }
        else {
            navigator.clipboard.writeText(`${process.env.REACT_APP_DOMAIN_NAME}/feeds/${this.state.alert.id}/`);
            this.setState({shouldDisplaySnackbar: true});
        }
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({shouldDisplaySnackbar: false});
    };

    render() {
        let menuData;
        if (keychain.isLoggedIn()) {
            menuData = [
                {name: 'Account', icon: <AccountCircleIcon/>, url:'/account'},
                {name: 'Sponsor', icon: <LoyaltyIcon/>, url:'/sponsor'},
                {name: 'Feeds', icon: <FeedIcon/>, url: '/feeds'}
            ];
        }
        else {
            menuData = [
                {name: 'Login', icon: <LockOpenIcon/>, url: '/login'},
                {name: 'Sign up', icon: <PersonAddAltIcon/>, url: '/signup'},
                {name: 'Sponsor', icon: <LoyaltyIcon/>, url:'/sponsor'},
                {name: 'Feeds', icon: <FeedIcon/>, url:'/feeds'}
            ];
        }

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClose={() => this.toggleDrawer(false)}>
            <List>
                {menuData.map((item, index) => (
                    <ListItem button component="a" href={item.url} disablePadding key={index}>
                        <ListItemButton>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Box>
    );

    var alertView = <Box />;
    var helmet = undefined;
    if (this.state.alert === undefined) {
        alertView = <Box
            sx={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <CircularProgress color="primary" />
                    </Box>;
    }
    else {
        alertView = <FeedAlertView 
            shareAlert={this.shareAlert}
            alert={this.state.alert}
            />;
        
        if (this.state.alert.resolved_at === null) {
            let title = `Have you seen ${this.state.alert.pet_name}? ${this.state.alert.description}`;
            helmet = <Helmet>
                <title>Have you seen {this.state.alert.pet_name}? {this.state.alert.description}</title>
                <meta name="og:title" content={title} />
                <meta name="description" content={this.state.alert.description} />
                <meta name="og:description" content={this.state.alert.description} />
                <meta name="og:image" content={this.state.alert.thumbnail_url} />
            </Helmet>
        }
        else {
            let title = `${this.state.alert.pet_name} is home safe! ${this.state.alert.description}`;
            helmet = <Helmet>
                <title>{this.state.alert.pet_name} is home safe! {this.state.alert.description}</title>
                <meta name="og:title" content={title} />
                <meta name="description" content={this.state.alert.description} />
                <meta name="og:description" content={this.state.alert.description} />
                <meta name="og:image" content={this.state.alert.thumbnail_url} />
            </Helmet>
        }
    }

    return (
        <React.Fragment>
            {helmet}
            <Box sx={{backgroundColor:"background.default"}}>
                <Box sx={{ flexGrow: 1 }}>
                    <HeaderBar menuAction={this.toggleMenu}/>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.menuOpen}
                    onClose={() => this.toggleDrawer(false)}
                >
                    {list()}
                </Drawer>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{minHeight: "100vh",
                        backgroundSize: 'cover',
                        backgroundColor: 'background.default'}}>
                    <Box
                        sx={{width: "100%",
                            maxWidth: "600px",
                            minHeight: "100vh",
                            backgroundSize: 'cover',
                            border: 1,
                            borderColor: "spotText.secondary",
                            p: 3}}>
                        {alertView}
                    </Box>
                </Box>
            </Box>
            <Snackbar open={this.state.shouldDisplaySnackbar} autoHideDuration={3000} onClose={this.handleCloseSnackbar}>
                <Alert onClose={this.handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                Link copied to clipboard!
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
  }
}

function FeedAlertWithNavigate(props) {
    const { id } = useParams();
    return <FeedAlert {...props} alertID={id} />
}

export default FeedAlertWithNavigate;
