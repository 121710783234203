import * as React from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import IosShareIcon from '@mui/icons-material/IosShare';

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

class FeedCard extends React.Component {
    constructor(props) {
        super(props);

        TimeAgo.addLocale(en);
    }

    render() {
        const timeAgo = new TimeAgo('en-US');

        var date = new Date(this.props.alert.created_at);
        let offset = date.getTimezoneOffset() / 60;
        let hours = date.getHours();
        date.setHours(hours - offset);

        var location = '—'
        let address_municipality = this.props.alert.address_municipality;
        let address_region = this.props.alert.address_region;
        let country = this.props.alert.address_country;
        if (address_municipality !== null && address_region !== null && country !== null) {
            location = `${address_municipality}, ${address_region}, ${country}`
        }

        return (
            <Card align='left' sx={{ width: 375, backgroundColor: 'action.selected' }}>
                <CardActionArea onClick={() => { this.props.selectedAlert(this.props.alert.id); }}>
                    <CardMedia
                    component="img"
                    height="375"
                    image={this.props.alert.thumbnail_url}
                    alt="Missing pet"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {this.props.alert.pet_name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{mb: 1, fontWeight: 600}}>
                            {timeAgo.format(date, 'mini')} — {location}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                        }}>
                            {this.props.alert.description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <IconButton
                        aria-label="share"
                        onClick={() => { this.props.shareAlert(this.props.alert.id); }}
                        >
                        <IosShareIcon />
                    </IconButton>
                </CardActions>
            </Card>
        );
    }
}

export default FeedCard;
