import * as React from 'react';
import client from './api';

import SignupForm from './SignupForm'
import PinEntry from './PinEntry'

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formVisble: true,
            phone: '',
            countryCode: '',
            formattedPhone: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
            pinError: false
        };
    }

    shouldComponentUpdate(nextProps, nextState) { 
        return (nextState.formVisble !== this.state.formVisble) || (nextState.pinError !== this.state.pinError);
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let firstName = data.get('firstName');
        let lastName = data.get('lastName');
        let emailAddress = data.get('email');

        if (firstName.length === 0 || lastName.length === 0 || emailAddress.length === 0 || this.state.phone.length === 0) {
            return;
        }

        this.setState({
            formVisble: false,
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress
        });

        const parameters = {
            phone_number: this.state.phone,
            country_code: this.state.countryCode
        };

        client.post('users/', parameters)
        .then(res => {
            this.updateUserDetails(res.data.id);
        })
        .catch(error => {
            if (error.response.status === 400) {
                this.requestAuthCode();
            }
        });
    };

    requestAuthCode() {
        const data = {
            phone_number: this.state.phone,
            country_code: this.state.countryCode
        };
        
        client.post('auth/otp/', data);
    };

    handleOnChange = (value, country) => {
        let phone = value.replace(/\D/g,'').substring(country.dialCode.length);
        this.setState({
            phone: phone,
            formattedPhone: value,
            countryCode: country.countryCode.toUpperCase()
        });
    }

    handlePinCodeEntryComplete = (event) => {
        const data = {
            phone_number: this.state.phone,
            country_code: this.state.countryCode,
            password: event
        };

        client.post('auth/token/', data)
        .then(res => {
            localStorage.setItem('phoneNumber', this.state.phone);
            localStorage.setItem('countryCode', this.state.countryCode);
            localStorage.setItem('accessToken', res.data.access_token);
            localStorage.setItem('refreshToken', res.data.refresh_token);
            localStorage.removeItem('location_zipcode');
            client.get('users/')
            .then(res => {
                localStorage.setItem('user_id', res.data[0]['id']);
                window.location.replace(`${process.env.REACT_APP_DOMAIN_NAME}/feeds`);
            });
        })
        .catch(error => {
            this.setState({pinError: true});
        });
    };

    handleIncorrectPhoneNumber = (event) => {
        this.setState({formVisble: true});
    };

    handleResendCode = (event) => {
        this.requestAuthCode();
    };

    updateUserDetails(userID) {
        const data = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email_address: this.state.emailAddress
        }

        client.put(`users/${userID}/`, data)
        .then(res => {
            this.requestAuthCode();
        })
        .catch(error => {
            this.requestAuthCode();
        });
    }

    render() {
        if (this.state.formVisble) {
            return <SignupForm 
                handleSubmit={this.handleSubmit}
                handleOnChange={this.handleOnChange}/>;
        }

          return <PinEntry 
            phone={this.state.formattedPhone}
            handlePinCodeEntryComplete={this.handlePinCodeEntryComplete}
            handleIncorrectPhone={this.handleIncorrectPhoneNumber}
            handleResendCode={this.handleResendCode}
            error={this.state.pinError}/>;
    }
}

export default Signup;
