import * as React from 'react';

import keychain from './keychain';
import MobileStoreButton from 'react-mobile-store-button';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';


import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FeedIcon from '@mui/icons-material/Feed';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

import HeaderBar from './HeaderBar';

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  toggleMenu = (event) => {
    this.toggleDrawer(!this.state.menuOpen)
  };

  toggleDrawer = (open) => {
      if (this.state.menuOpen === open) {
        return;
      }

      this.setState({menuOpen: open});
    };

  render() {
    const iOSUrl = 'https://apps.apple.com/us/app/spot-bring-pets-home-faster/id1498398482?ls=1';

    const footers = [
      {
        title: 'Social',
        description: [
          {name:'Instagram', url: 'https://www.instagram.com/GetSpotHome'},
          {name: 'Twitter', url: 'https://www.twitter.com/GetSpotHome'},
          {name: 'Facebook', url: 'https://www.facebook.com/GetSpotHome'}
        ]
      },
      {
        title: 'Legal',
        description: [
          {name:'Privacy', url: 'privacy.html'},
          {name: 'Terms', url: 'terms.html'}
        ]
      }
    ];

    let menuData;
    if (keychain.isLoggedIn()) {
      menuData = [
        {name: 'Account', icon: <AccountCircleIcon/>, url:'/account'},
        {name: 'Sponsor', icon: <LoyaltyIcon/>, url:'/sponsor'},
        {name: 'Feeds', icon: <FeedIcon/>, url: '/feeds'}
      ];
    }
    else {
      menuData = [
        {name: 'Login', icon: <LockOpenIcon/>, url: '/login'},
        {name: 'Sign up', icon: <PersonAddAltIcon/>, url: '/signup'},
        {name: 'Sponsor', icon: <LoyaltyIcon/>, url:'/sponsor'},
        {name: 'Feeds', icon: <FeedIcon/>, url:'/feeds'}
      ];
    }

    const list = () => (
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClose={() => this.toggleDrawer(false)}
      >
        <List>
          {menuData.map((item, index) => (
            <ListItem button component="a" href={item.url} disablePadding key={index}>
              <ListItemButton>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Box>
    );

    return (
      <React.Fragment>
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
        <Box sx={{backgroundColor:"background.default"}}>
          <Box sx={{ flexGrow: 1 }}>
            <HeaderBar menuAction={this.toggleMenu}/>
          </Box>
          <Drawer
            anchor={'right'}
            open={this.state.menuOpen}
            onClose={() => this.toggleDrawer(false)}
          >
            {list()}
          </Drawer>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{height: "100vh",
              minHeight: "775px",
              backgroundSize: 'cover',
              backgroundImage: `url(${"/img/landing/landing_background.png"})`}}>
                <Container>
                  <Box
                    sx={{maxWidth: '500px', ml: 2, mt: 10}}>
                    <Typography variant="h2" color="landingCopy.main" sx={{mb: 6}}>
                      Spot helps bring pets home.
                    </Typography>
                    <Typography variant="h4" color="landingCopy.main" sx={{mb: 4}}>
                      Receive notifications when a pet in your area goes missing.
                    </Typography>
                    <Typography variant="h4" color="landingCopy.main" sx={{mb: 4}}>
                      Send notifications to those in your area if your pet goes missing.
                    </Typography>
                    <MobileStoreButton
                            store="ios"
                            url={iOSUrl}
                            linkProps={{ title: 'iOS Store Button' }}
                            width={140}
                          />
                  </Box>
                </Container>
            </Box>
            <Container
              sx={{my: 5}}
            >
              <Grid container justifyContent="space-evenly">
                {footers.map((footer) => (
                  <Grid item key={footer.title}>
                    <Typography variant="body1" color="text.primary" gutterBottom sx={{fontWeight: 600}}>
                      {footer.title}
                    </Typography>
                    <ul sx={{"& .list-style-type": "none"}}>
                      {footer.description.map((item) => (
                        <li key={item}>
                          <Link href={item.url} variant="subtitle1" color="text.secondary" target="_blank">
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                ))}
              </Grid>
              <Typography variant="body2" align="center" color="text.secondary" sx={{py: 4}}>
                {'Copyright © '}
                <Link href="https://www.kleincodes.com/" target="_blank">
                  KleinCodes, LLC
                </Link>{' '}
                {new Date().getFullYear()}
                {'. All Rights Reserved. v1.0.1'}
              </Typography>
            </Container>
          </Box>
        </React.Fragment>
    );
  }
}

export default Landing;
