import * as React from 'react';
import { useNavigate } from "react-router-dom";

import client from './api';
import keychain from './keychain';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FeedIcon from '@mui/icons-material/Feed';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

import HeaderBar from './HeaderBar';

import AccountForm from './AccountForm'

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            filterOpen: false,
            filterAnchor: null,
            accountData: undefined,
            shouldDisplaySuccessSnackbar: false,
            shouldDisplayFailureSnackbar: false
        };

        this.didRequestAccountDetails = false
    }

    componentDidMount() {
        if (this.didRequestAccountDetails === false) {
            this.didRequestAccountDetails = true;
            this.requestAccountDetails();
        }
    }

    toggleMenu = (event) => {
        this.toggleDrawer(!this.state.menuOpen)
    };

    toggleDrawer = (open) => {
        if (this.state.menuOpen === open) {
            return;
        }

        this.setState({menuOpen: open});
    };

    openFilter = (event) => {
        this.setState({filterOpen: true, filterAnchor: event.currentTarget})
    }

    requestAccountDetails = () => {
        client.get('users/')
        .then(res => {
            this.setState({accountData: res.data[0]});
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let firstName = data.get('firstName');
        let lastName = data.get('lastName');
        let emailAddress = data.get('email');

        if (firstName.length === 0 || lastName.length === 0 || emailAddress.length === 0) {
            return;
        }

        const parameters = {
            first_name: firstName,
            last_name: lastName,
            email_address: emailAddress
        }

        client.put(`users/${this.state.accountData.id}/`, parameters)
        .then(res => {
            this.setState({shouldDisplaySuccessSnackbar: true, shouldDisplayFailureSnackbar: false});
        })
        .catch(error => {
            this.setState({shouldDisplaySuccessSnackbar: false, shouldDisplayFailureSnackbar: true});
        });
    };

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({shouldDisplaySuccessSnackbar: false, shouldDisplayFailureSnackbar: false});
    };

    logout = () => {
        keychain.logout()
        this.props.navigate('/')
    };

    render() {
        let menuData;
        if (keychain.isLoggedIn()) {
            menuData = [
                {name: 'Account', icon: <AccountCircleIcon/>, url:'/account'},
                {name: 'Sponsor', icon: <LoyaltyIcon/>, url:'/sponsor'},
                {name: 'Feeds', icon: <FeedIcon/>, url: '/feeds'}
            ];
        }
        else {
            window.location.replace('/login');
        }

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClose={() => this.toggleDrawer(false)}>
            <List>
                {menuData.map((item, index) => (
                    <ListItem button component="a" href={item.url} disablePadding key={index}>
                        <ListItemButton>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Box>
    );

    var accountForm = <Box />;
    if (this.state.accountData === undefined) {
        accountForm = <Box
            sx={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <CircularProgress color="primary" />
                    </Box>;
    }
    else {
        accountForm = <AccountForm 
            handleSubmit={this.handleSubmit}
            handleLogout={this.logout}
            accountData={this.state.accountData}
            />;
    }

    return (
        <React.Fragment>
            <Box>
                <Box sx={{ flexGrow: 1 }}>
                    <HeaderBar menuAction={this.toggleMenu}/>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.menuOpen}
                    onClose={() => this.toggleDrawer(false)}
                >
                    {list()}
                </Drawer>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{minHeight: "100vh",
                        backgroundSize: 'cover'}}>
                    <Box
                        sx={{width: "100%",
                            maxWidth: "600px",
                            minHeight: "100vh",
                            backgroundSize: 'cover',
                            border: 1,
                            borderColor: "spotText.secondary",
                            p: 3}}>
                        {accountForm}
                    </Box>
                </Box>
            </Box>
            <Snackbar open={this.state.shouldDisplaySuccessSnackbar} autoHideDuration={3000} onClose={this.handleCloseSnackbar}>
                <Alert onClose={this.handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                Account details successfully saved!
                </Alert>
            </Snackbar>
            <Snackbar open={this.state.shouldDisplayFailureSnackbar} autoHideDuration={3000} onClose={this.handleCloseSnackbar}>
                <Alert onClose={this.handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                Failed to update account. Please try again later.
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
  }
}

function AccountWithNavigate(props) {
    let navigate = useNavigate();
    return <Account {...props} navigate={navigate} />
}

export default AccountWithNavigate;
