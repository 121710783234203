import * as React from 'react';

import Moment from 'moment';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

class ManageSponsorshipTable extends React.Component {
    render() {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Typography component="h1" variant="h5">
                    Manage Sponsorships
                </Typography>
                <TableContainer sx={{pt: 6, width: "120%"}}>
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">ZIP code</TableCell>
                            <TableCell align="center">Created</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Edit</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {this.props.sponsorshipData.map((sponsorship) => (
                            <TableRow
                            key={sponsorship.uuid}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row" align="center">
                                {sponsorship.zipcode}
                            </TableCell>
                            <TableCell align="center">{Moment(sponsorship.start).format('M/D/YY')}</TableCell>
                            <TableCell component="th" scope="row" align="center">
                                {sponsorship.status.charAt(0).toUpperCase() + sponsorship.status.slice(1)}
                            </TableCell>
                            <TableCell align="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    disabled={sponsorship.end === undefined || sponsorship.status === 'canceled'}
                                    onClick={() => { this.props.selectedSponsorship(sponsorship.uuid); }}
                                    sx={{ mt: 3, mb: 2, textTransform: "none" }}>
                                    Edit
                                </Button>
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Box>
            </Container>
        );
    }
}

export default ManageSponsorshipTable;
