import * as React from 'react';
import LoginForm from './LoginForm'
import PinEntry from './PinEntry'
import client from './api';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formVisble: true,
            phone: '',
            countryCode: '',
            formattedPhone: '',
            pinError: false
        };
    }

    shouldComponentUpdate(nextProps, nextState) { 
        return (nextState.formVisble !== this.state.formVisble) || (nextState.pinError !== this.state.pinError);
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({formVisble: false});
        
        this.requestAuthCode();
    };

    requestAuthCode() {
        const data = {
            phone_number: this.state.phone,
            country_code: this.state.countryCode
        };
        
        client.post('auth/otp/', data);
    };

    handleOnChange = (value, country) => {
        let phone = value.replace(/\D/g,'').substring(country.dialCode.length);
        this.setState({
            phone: phone,
            formattedPhone: value,
            countryCode: country.countryCode.toUpperCase()
        });
     }

    handlePinCodeEntryComplete = (event) => {
        const data = {
            phone_number: this.state.phone,
            country_code: this.state.countryCode,
            password: event
        };
        client.post('auth/token/', data)
        .then(res => {
            localStorage.setItem('phoneNumber', this.state.phone);
            localStorage.setItem('countryCode', this.state.countryCode);
            localStorage.setItem('accessToken', res.data.access_token);
            localStorage.setItem('refreshToken', res.data.refresh_token);
            localStorage.removeItem('location_zipcode');
            client.get('users/')
            .then(res => {
                localStorage.setItem('user_id', res.data[0]['id']);
                window.location.replace(`${process.env.REACT_APP_DOMAIN_NAME}/feeds`);
            });
        })
        .catch(error => {
            this.setState({pinError: true});
        });
    };

    handleIncorrectPhoneNumber = (event) => {
        this.setState({formVisble: true});
    };

    handleResendCode = (event) => {
        this.requestAuthCode();
    };

    render() {
        if (this.state.formVisble) {
            return <LoginForm 
                handleSubmit={this.handleSubmit}
                handleOnChange={this.handleOnChange}/>;
          }

        return <PinEntry 
            phone={this.state.formattedPhone}
            handlePinCodeEntryComplete={this.handlePinCodeEntryComplete}
            handleIncorrectPhone={this.handleIncorrectPhoneNumber}
            handleResendCode={this.handleResendCode}
            error={this.state.pinError}/>;
    }
}

export default Login;
