const keychain = {
    isLoggedIn() {
        return localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null;
    },

    logout() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('phoneNumber');
        localStorage.removeItem('countryCode');
        localStorage.removeItem('user_id');
        localStorage.removeItem('location_zipcode');
    },

    setWithExpiry(key, value, ttl) {
        const now = new Date()
    
        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item))
    },

    getWithExpiry(key) {
        const itemStr = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return null
        }
        return item.value
    },

    doesKeyExist(key) {
        return localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null;
    }
};
  
export default keychain;