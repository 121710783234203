import * as React from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import LoadingButton from '@mui/lab/LoadingButton';

class EditSponsorshipForm extends React.Component {
    render() {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Typography component="h1" variant="h5" color="text.primary">
                    Edit Sponsorship
                </Typography>
                <Box component="form" noValidate onSubmit={this.props.handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                        name="companyName"
                        required
                        fullWidth
                        id="companyName"
                        label="Name"
                        variant="outlined"
                        defaultValue={this.props.sponsorshipData.company_name}
                        helperText= {this.props.errors.includes('company_name') ? "Invalid input" : ""}
                        error={this.props.errors.includes('company_name')}
                        autoFocus
                        sx={{
                            "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                  borderColor: "primary.main"
                                }
                              }
                          }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        id="companyURL"
                        label="URL"
                        name="companyURL"
                        variant="outlined"
                        defaultValue={this.props.sponsorshipData.company_url}
                        sx={{
                            "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                  borderColor: "primary.main"
                                }
                              }
                          }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        multiline
                        id="description"
                        label="Description"
                        name="description"
                        variant="outlined"
                        defaultValue={this.props.sponsorshipData.description}
                        maxRows={4}
                        sx={{
                            "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                  borderColor: "primary.main"
                                }
                              }
                          }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        id="zipcode"
                        label="ZIP"
                        name="zipcode"
                        autoComplete="postal-code"
                        variant="outlined"
                        defaultValue={this.props.sponsorshipData.zipcode}
                        helperText= {this.props.errors.includes('zipcode') ? "Invalid input" : ""}
                        error={this.props.errors.includes('zipcode')}
                        sx={{
                            "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                  borderColor: "primary.main"
                                }
                              }
                          }}
                        />
                        <Typography component="p" variant="p" color="text.secondary" sx={{ mt: 2 }}>
                            *Enter the zipcode of the area you would like to sponsor. All neighboring towns will be sponsored as well!
                        </Typography>
                    </Grid>
                    </Grid>
                    <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={this.props.loadingPayment}
                    sx={{ mt: 3, mb: 2, textTransform: "none" }}
                    >
                        Update
                    </LoadingButton>
                    <Divider sx={{my:4}}/>
                    <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => { this.props.manageSubscription(); }}
                    sx={{ mt: 2, mb: 2, textTransform: "none" }}
                    >
                        Manage subscription & billing details
                    </Button>
                </Box>
                </Box>
            </Container>
        );
    }
}

export default EditSponsorshipForm;
