import * as React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

class SponsorForm extends React.Component {
    render() {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Typography component="h1" variant="h5" color="spotText.main" sx={{mb:5}}>
                    Tell us about yourself!
                </Typography>
                <Box component="form" noValidate onSubmit={this.props.handleSubmit}>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                        name="companyName"
                        required
                        fullWidth
                        id="companyName"
                        label="Name"
                        variant="outlined"
                        helperText= {this.props.errors.includes('company_name') ? "Invalid input" : ""}
                        error={this.props.errors.includes('company_name')}
                        autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        id="companyURL"
                        label="URL"
                        name="companyURL"
                        variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        variant="outlined"
                        multiline
                        maxRows={4}
                        inputProps={{ maxLength: 500 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        id="zipcode"
                        label="ZIP"
                        name="zipcode"
                        autoComplete="postal-code"
                        variant="outlined"
                        helperText= {this.props.errors.includes('zipcode') ? "Invalid input" : ""}
                        error={this.props.errors.includes('zipcode')}
                        />
                        <Typography component="p" variant="p" color="spotText.secondary" sx={{ mt: 2 }}>
                            *Enter the zipcode of the area you would like to sponsor. All neighboring towns will be sponsored as well!
                        </Typography>
                    </Grid>
                    </Grid>
                    <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={this.props.loadingPayment}
                    sx={{ mt: 3, mb: 2, textTransform: "none" }}
                    >
                        Continue
                    </LoadingButton>
                </Box>
                </Box>
            </Container>
        );
    }
}

export default SponsorForm;
